import React from 'react'
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'
import HeroSec from '../Hero/HeroSec'
import Services from './Services'
import ServicesList from './ServicesList'
import Quote from '../Contact/Quote'

const Solutions = () => {
  return (
    <>
    <Navigation />
    <HeroSec sectTitle='Services'/>
    <Services />
    <ServicesList />
    <Quote />
    <Footer />
    </>
  )
}

export default Solutions