import React from 'react'
import './Hero.css'

const Hero = ({sectTitle}) => {
    return (
        <div className='heroSec'>
            <div
                className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto h-full flex items-center justify-center md:justify-start'>
                <h3
                    className='text-4xl sm:text-5xl font-bold text-white text-center md:text-start leading-9 md:leading-14'>{sectTitle}</h3>
            </div>
        </div>
    )
}

export default Hero