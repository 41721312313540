import React from 'react'
import './Hero.css'
import {NavLink} from 'react-router-dom'

const Hero = () => {
    return (
        <div className='hero'>
            <div
                className='max-w-6xl px-2 md:px-4 xl:px-8 h-[100vh] mx-auto flex flex-col gap-2 items-center md:items-start justify-center'>
                <h3
                    className='text-4xl sm:text-5xl font-bold text-white text-center md:text-start leading-9 md:leading-14 w-64'>Modern Innovative Technologies</h3>
                <p
                    className='text-base text-white text-center md:text-start leading-6 md:leading-8 mb-2 w-full px-1 md:px-0 md:w-4/5 xl:w-3/5'>
                    Brainbit Solutions is a young, dynamic, and progressively growing company with a very clear objective: to proffer innovative and cost-effective solutions through efficient deployment and integration of information technology.
                </p>
                <NavLink to='/about'>
                    <button
                        className='bg-white rounded text-blue-900 px-6 py-4 flex gap-2 items-center'>
                        About Brainbit Solutions
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6 text-blue-900">
                            <path
                                fillRule="evenodd"
                                d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"/>
                        </svg>
                    </button>
                </NavLink>
            </div>
        </div>
    )
}

export default Hero