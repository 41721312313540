import React from 'react'
import './Solutions.css'
import Services_Img_1 from '../../Assets/Images/dg.jpg'
import Services_Img_2 from '../../Assets/Images/dgm.jpg'
import Services_Img_3 from '../../Assets/Images/opm.jpg'

const ServicesList = () => {
    return (
        <div className='servicesContainer bg-gray-100'>
            <div className='max-w-6xl mx-auto px-2 md:px-4 xl:px-8'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col-reverse md:flex-row items-center md:mb-[3rem]'>
                        <div className='basis-1/2 md:pr-6 mb-4'>
                            <h3 className='text-4xl text-blue-900 capitalize text-center mb-4'>Enterprise Web & Mobile Application Solutions</h3>
                            <p className='text-center'>
                            We offer innovative web solutions that can nurture your ideas to life as well as improve your online presence. We employ an incredible Mobile First approach in all our delivered products.
                            </p>
                        </div>
                        <div className='basis-1/2 mb-4'>
                            <img src={Services_Img_1} alt="cloud services" className='rounded-lg' />
                        </div>
                    </div>
                    <div className='md:flex items-center md:mb-[3rem]'>
                        <div className='basis-1/2 mb-4'>
                            <img src={Services_Img_2} alt="cloud services" className='rounded-lg' />
                        </div>
                        <div className='basis-1/2 md:pr-6 mb-4'>
                            <h3 className='text-4xl text-blue-900 capitalize text-center mb-4'>Enterprise Solutions</h3>
                            <p className='text-center'>
                            We know that success depends on high-level performance on every task at hand. We offer professional consultation on setting up and effectively implementing and using top enterprise solutions (Microsoft Sharepoint).
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col-reverse md:flex-row items-center md:mb-[3rem]'>
                        <div className='basis-1/2 md:pr-6 mb-4'>
                            <h3 className='text-4xl text-blue-900 capitalize text-center mb-4'>Corporate Supply & Maintenance</h3>
                            <p className='text-center'>
                            To succeed in today's technology-driven environment, a partner is always ready to offer a supply of computer hardware devices and prompt maintenance services of existing IT infrastructure at any moment of notice.
                            </p>
                        </div>
                        <div className='basis-1/2 mb-4'>
                            <img src={Services_Img_3} alt="cloud services" className='rounded-lg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesList