import React from 'react'
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'
import HeroSec from '../Hero/HeroSec'
import AboutSection1 from './AboutSection1'
import AboutSection from './AboutSection'
import OurTeam from './OurTeam'
import OpenSection from '../OpenSection'

const About = () => {
  return (
    <>
    <Navigation />
    <HeroSec sectTitle='About Us'/>
    <AboutSection1 />
    <AboutSection />
    <OurTeam />
    <OpenSection />
    <Footer />
    </>
  )
}

export default About