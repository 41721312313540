import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Favicon from '../src/Assets/Images/favicon-bs.jpg'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Contact from './Components/Contact/Contact'
import Solutions from './Components/Solutions/Solutions'

const App = () => {
  
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/png';
    link.href = Favicon;
    document.head.appendChild(link);
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/solutions" element={<Solutions/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
    </>
  )
}

export default App