import React from 'react'
import Navigation from '../Navigation/Navigation'
import Footer from '../Footer/Footer'
import Quote from './Quote'
import HeroSec from '../Hero/HeroSec' 
import ContactSection1 from './ContactSection1'

const Contact = () => {
  return (
    <>
    <Navigation />
    <HeroSec sectTitle='Contact Us' />
    <ContactSection1 />
    <Quote />
    <Footer />
    </>
  )
}

export default Contact