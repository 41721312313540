import React from 'react'
import './Contact.css'

const ContactSection1 = () => {
    return (
        <div className='quoteContainer bg-gray-100'>
            <div className='max-w-6xl mx-auto px-2 md:px-4 xl:px-8'>
                <div className='md:flex gap-2'>
                    <div className='basis-1/2 mb-3'>
                        <h5 className='text-sm text-blue-900 uppercase mb-6'>Let's Make it Happens</h5>
                        <h3 className='md:text-5xl md:mb-4 text-6xl font-bold text-blue-900 leading-[5rem]'>
                            Don't Hesitate To Get in Touch.
                        </h3>
                        <p className='leading-6'>
                            We help customers with business innovation powered by technology via computers,
                            networking, communications, hardware equipment, data/information storage and
                            management
                        </p>
                    </div>
                    <div className='basis-1/2 mb-3 md:pl-[5rem]'>
                        <div className='flex flex-col gap-5'>
                            <div className='flex gap-3 mb-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                        clipRule="evenodd"/>
                                </svg>
                                <div>
                                    <h5 className='text-blue-900 uppercase text-md mb-2'>address</h5>
                                    <p className='text-normal'>12 Kwara Street, Off Akowonjo Road, Egbeda, Lagos.</p>
                                </div>
                            </div>
                            <div className='flex gap-3 mb-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="M19.5 9.75a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l4.72-4.72a.75.75 0 1 1 1.06 1.06L16.06 9h2.69a.75.75 0 0 1 .75.75Z"
                                        clipRule="evenodd"/>
                                    <path
                                        fillRule="evenodd"
                                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                        clipRule="evenodd"/>
                                </svg>
                                <div>
                                    <h5 className='text-blue-900 uppercase text-md mb-2'>contact number</h5>
                                    <p className='text-normal'>+234-7030373137</p>
                                </div>
                            </div>
                            <div className='flex gap-3'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"/>
                                    <path
                                        d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"/>
                                </svg>
                                <div>
                                    <h5 className='text-blue-900 uppercase text-md mb-2'>contact email</h5>
                                    <p className='text-normal'>info@brainbitsolutions.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSection1