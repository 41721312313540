import React from 'react'
import './Solutions.css'

const Solution = () => {
    return (
        <div className='servicesContainer w-full bg-gray-100'>
            <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
                <div className='md:flex px-2 py-4 md:px-0 gap-4 items-start justify-center'>
                    <div className='rounded basis-1/5 mb-2'>
                        <h3 className='font-normal text-blue-900 uppercase'>what we do</h3>
                    </div>
                    <div className='rounded basis-4/5 mb-2'>
                        <div className='flex flex-col gap-3'>
                            <h3 className='text-4xl sm:text-5xl leading-15 w-11/12 xl:w-3/5 text-blue-900'>Full-service Internet Marketing Solutions</h3>
                            <p className='leading-8'>Our expertise spans across web development, design,
                                graphic design, and IT consultation. We are dedicated to delivering high-quality
                                services that meet and exceed our clients’ expectations.</p>
                        </div>
                        <hr className='text-white bg-white my-[50px] h-[1px] border-0'/>
                        <div className='md:mt-4 md:flex justify-start items-start gap-3'>
                            <div className='basis-1/3 flex flex-col gap-3 mb-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"/>
                                </svg>

                                <h4 className='font-bold text-blue-900'>Web Development</h4>
                                <p>
                                We offer innovative web solutions that can nurture your ideas to life as well as improve your online presence. We employ an incredible Mobile First approach in all our delivered products.
                                </p>
                            </div>
                            <div className='basis-1/3 flex flex-col gap-3 mb-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM8.547 4.505a8.25 8.25 0 1 0 11.672 8.214l-.46-.46a2.252 2.252 0 0 1-.422-.586l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 0 1-1.384-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.279-2.132Z"
                                        clipRule="evenodd"/>
                                </svg>

                                <h4 className='font-bold text-blue-900'>Enterprise Solutions</h4>
                                <p>
                                We know that success depends on high-level performance on every task at hand. We offer professional consultation on setting up and effectively implementing and using top enterprise solutions (Microsoft Sharepoint).
                                </p>
                            </div>
                            <div className='basis-1/3 flex flex-col gap-3 mb-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-6 text-blue-900">
                                    <path
                                        fillRule="evenodd"
                                        d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 0 0 1.28.53l4.184-4.183a.39.39 0 0 1 .266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0 0 12 2.25ZM8.25 8.625a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm2.625 1.125a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                                        clipRule="evenodd"/>
                                </svg>
                                <h4 className='font-bold text-blue-900'>Corporate Supply & Maintenance</h4>
                                <p>
                                To succeed in today's technology-driven environment, a partner is always ready to offer a supply of computer hardware devices and prompt maintenance services of existing IT infrastructure at any moment of notice. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Solution