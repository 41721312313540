import React from 'react'
import './About.css'

const AboutSection = () => {
    return (
        <div className='abtSection w-full bg-gray-900'>
            <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
                <div className='lg:flex justify-center items-center gap-4'>
                    <div className='lg:basis-2/5 mb-3'>
                        <h2 className='text-5xl text-white leading-[4rem] lg:w-4/5'>Our Brand, Our Vision</h2>
                    </div>
                    <div className='flex flex-col lg:basis-3/5'>
                        <div className='lg:px-4 mb-4'>
                            <h2 className='text-2xl text-white font-bold capitalize'>Web Design & Application Development</h2>
                            <p className='text-base mt-2 text-white'>
                                Through knowledge-based application of modern technology in website design and
                                development, you are assured to always get the best solution that meets your
                                innermost desire while productively engaging your audience as well as promote
                                your online presence to enhance your growth. We will be glad to have a
                                discussion with you about your Personal or Business, E-Commerce, or E-Learning
                                website, so we can amaze you with our professional touch.
                            </p>
                        </div>
                        <div className='lg:px-4 mb-4'>
                            <h2 className='text-2xl text-white font-bold capitalize'>Enterprise Software Application Solutions</h2>
                            <p className='text-base mt-2 text-white'>
                                With very good grasp of major Microsoft solutions (Office 365 and Sharepoint),
                                we have seen the organizations blossom from within through collaboration and
                                ease of information access and usage. We offer setup, training, and consultation
                                on enterprise application software solutions that perfectly suit your business
                                needs.
                            </p>
                        </div>
                        <div className='lg:px-4 mb-4'>
                            <h2 className='text-2xl text-white font-bold capitalize'>Corporate Supply & Maintenance</h2>
                            <p className='text-base mt-2 text-white'>
                                Effective auditing of your current technology strength is the logical first step
                                in ensuring your capability to deliver business solutions required both
                                immediately and in the future. Brainbit Solutions offer supply and support
                                services which include new software releases installation and analysis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection