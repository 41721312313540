import React from 'react'
import Facebook_Icon from '../../Assets/Images/facebook.png'
import Instagram_Icon from '../../Assets/Images/instagram.png'
import Linkedin_Icon from '../../Assets/Images/linkedin.png'
import Rasaq from '../../Assets/Images/rasaq.png'
import Team2 from '../../Assets/Images/team2.jpeg'
import Team3 from '../../Assets/Images/team3.jpeg'
import Team4 from '../../Assets/Images/team4.png'

const OurTeam = () => {
    return (
        <div className='ourTeam bg-gray-100'>
            <div className='max-w-6xl mx-auto px-2 md:px-4 xl:px-8'>
                <h3 className='text-5xl text-center text-blue-900 mb-[3rem]'>Meet Our Team</h3>
                <div className='md:flex md:justify-start justify-center flex-wrap'>
                    <div className='md:basis-1/2 lg:basis-1/3 xl:basis-1/4 mb-3 px-2 '>
                        <div
                            className='bg-gray-200/25 rounded-md shadow-md flex flex-col gap-2 justify-center md:justify-start items-center p-3 md:h-[360px] lg:h-[420px]'>
                            <img src={Rasaq} alt="" className='w-24 h-24 box-border shadow-md shadow-blue-900 rounded-full'/>
                            <h4 className='text-blue-900'>Rasaq Olamilekan</h4>
                            <div className='flex items-center gap-1'>
                                <img src={Facebook_Icon} alt="" className='w-3' />
                                <img src={Instagram_Icon} alt="" className='w-3' />
                                <img src={Linkedin_Icon} alt="" className='w-3' />
                            </div>
                            <p className='text-sm'>
                                Rasaq combines expert technical knowledge with a real eye for database
                                management & software application development. He leads our technical and web
                                development affairs, working with clients from a wide range of industries, he
                                fully understands client objectives when working on a project, large or small.
                            </p>
                        </div>
                    </div>
                    <div className='md:basis-1/2 lg:basis-1/3 xl:basis-1/4 mb-3 px-2 '>
                        <div
                            className='bg-gray-200/25 rounded-md shadow-md flex flex-col gap-2 justify-center md:justify-start items-center p-3 md:h-[360px] lg:h-[420px]'>
                            <img src={Team2} alt="" className='w-24 h-24 box-border shadow-md shadow-blue-900 rounded-full'/>
                            <h4 className='text-blue-900'>Adeniyi Adegboyega</h4>
                            <div className='flex items-center gap-1'>
                                <img src={Facebook_Icon} alt="" className='w-3' />
                                <img src={Instagram_Icon} alt="" className='w-3' />
                                <img src={Linkedin_Icon} alt="" className='w-3' />
                            </div>
                            <p className='text-sm'>
                                Adeniyi (Prinx) is a trained business administration strategist, experienced
                                business manager and a leader in the making. He understands the synergy between
                                great product delivery and customer satisfaction as an important key for
                                business growth. Our business strategist is a lover of God, people, agriculture
                                and music.
                            </p>
                        </div>
                    </div>
                    <div className='md:basis-1/2 lg:basis-1/3 xl:basis-1/4 mb-3 px-2 '>
                        <div
                            className='bg-gray-200/25 rounded-md shadow-md flex flex-col gap-2 justify-center md:justify-start items-center p-3 md:h-[360px] lg:h-[420px]'>
                            <img src={Team3} alt="" className='w-24 h-24 box-border shadow-md shadow-blue-900 rounded-full'/>
                            <h4 className='text-blue-900'>Misturah Oyeniran</h4>
                            <div className='flex items-center gap-1'>
                                <img src={Facebook_Icon} alt="" className='w-3' />
                                <img src={Instagram_Icon} alt="" className='w-3' />
                                <img src={Linkedin_Icon} alt="" className='w-3' />
                            </div>
                            <p className='text-sm'>
                                Creating an environment where clients feel at home and expressive gives Misturah
                                much pleasure as a member of the team. She is an Educationist by academic
                                qualification with deep knowledge and experience in customer relationships, her
                                role is an important pillar in our firm.
                            </p>
                        </div>
                    </div>
                    <div className='md:basis-1/2 lg:basis-1/3 xl:basis-1/4 mb-3 px-2 '>
                        <div
                            className='bg-gray-200/25 rounded-md shadow-md flex flex-col gap-2 justify-center md:justify-start items-center p-3 md:h-[360px] lg:h-[420px]'>
                            <img src={Team4} alt="" className='w-24 h-24 box-border shadow-md shadow-blue-900 rounded-full'/>
                            <h4 className='text-blue-900'>Itunu Falohun</h4>
                            <div className='flex items-center gap-1'>
                                <img src={Facebook_Icon} alt="" className='w-3' />
                                <img src={Instagram_Icon} alt="" className='w-3' />
                                <img src={Linkedin_Icon} alt="" className='w-3' />
                            </div>
                            <p className='text-sm'>
                                Itunu (IT) represent the beacon guiding all sales and marketing drive of our
                                firm. His advisory contributions as an experience sales and marketing strategist
                                provided the much needed direction towards the growth of the firm. An
                                interaction with him will amaze you. IT has a growing facebook group where he
                                shares some of his sales strategies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam