import React from 'react'
import './Contact.css'

const Quote = () => {
  return (
    <div className='quoteContainer bg-gray-900'>
        <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
            <div className='md:flex gap-2'>
                <div className='basis-1/2  mb-4 md:mb-0'>
                    <h3 className='text-5xl leading-16 capitalize text-white mb-3'>Ready to take your business to the next level?</h3>
                    <p className='text-white'>
                    Brainbit solution is especially experienced at purchase, installation, and maintenance/upgrading of IT infrastructures. Our work guarantees greater user satisfaction and an increase in the business revenues of our clients. Our ICT solutions utilizes international standards.
                    </p>
                </div>
                <div className='basis-1/2 px-6'>
                    <h3 className='text-5xl text-white mb-3'>Let's talk</h3>
                    <form>
                        <input type="text" className='p-4 rounded w-full mb-3' placeholder='Full name' required />
                        <input type="email" className='p-4 rounded w-full mb-3' placeholder='Email address' required />
                        <textarea name="" id="" className='w-full rounded p-4 mb-3' placeholder='Your message' rows="6" required></textarea>
                        <input type="submit" value="Get a Quote" className='p-4 bg-blue-900 text-white rounded-lg' />
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Quote