import React from 'react'
import Service_Option_1 from '../../Assets/Images/network.png'

const Services = () => {
    return (
        <div className='servicesContainer servicesCon w-full bg-gray-200'>
            <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
                <h4 className='text-blue-900 font-normal uppercase text-center mb-8'>our services</h4>
                <h3 className='text-blue-900 text-5xl font-bold text-center leading-16 mb-4'>Take Your Business to the next Level</h3>
                <p className='text-center md:w-11/12 mx-auto'>
                    At Brainbit solution, our mission is to deliver excellent value to our clients,
                    through the provisions of IT management services and consultancy, for them to do
                    business more conveniently, optimize their business processes and achieve their
                    revenue and profit goals.
                </p>
                <div
                    className='flex flex-wrap justify-center md:gap-2 lg:gap-4 w-full mt-[30px]'>
                    <div
                        className='basis-full md:basis-[49%] rounded-lg shadow h-64 mb-3 md:mb-1 lg:mb-2 bg-white p-6'>
                        <div className='flex flex-col gap-3 lg:gap-4'>
                            <img src={Service_Option_1} alt="" className='w-12'/>
                            <h4 className='font-normal text-2xl capitalize text-blue-900'>Digital Strategy</h4>
                            <p>
                                We provide result-proven strategies that guarantees success for your business using technology.
                            </p>
                        </div>
                    </div>
                    <div
                        className='basis-full md:basis-[49%] rounded-lg shadow h-64 mb-3 md:mb-1 lg:mb-2 bg-white p-6'>
                        <div className='flex flex-col gap-3 lg:gap-4'>
                            <img src={Service_Option_1} alt="" className='w-12'/>
                            <h4 className='font-normal text-2xl capitalize text-blue-900'>Business Strategy</h4>
                            <p>
                                We provide result-proven business strategies that has been tested and guarantees success.
                            </p>
                        </div>
                    </div>
                    <div
                        className='basis-full md:basis-[49%] rounded-lg shadow h-64 mb-3 md:mb-1 lg:mb-2 bg-white p-6'>
                        <div className='flex flex-col gap-3 lg:gap-4'>
                            <img src={Service_Option_1} alt="" className='w-12'/>
                            <h4 className='font-normal text-2xl capitalize text-blue-900'>Cloud Services</h4>
                            <p>
                                We provide cloud services for your business applications.
                            </p>
                        </div>
                    </div>
                    <div
                        className='basis-full md:basis-[49%] rounded-lg shadow h-64 mb-3 md:mb-1 lg:mb-2 bg-white p-6'>
                        <div className='flex flex-col gap-3 lg:gap-4'>
                            <img src={Service_Option_1} alt="" className='w-12'/>
                            <h4 className='font-normal text-2xl capitalize text-blue-900'>Design & Development</h4>
                            <p>
                                We provide trending designs and development that meets your business objectives.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services