import React from 'react'
import './Partners.css'
import Main_Partner from '../../Assets/Images/mp.png'
import Partner_1 from '../../Assets/Images/p_1.png'
import Partner_2 from '../../Assets/Images/p_2.png'
import Partner_3 from '../../Assets/Images/p_3.png'
import Partner_4 from '../../Assets/Images/p_4.png'
import Partner_5 from '../../Assets/Images/p_5.png'

const Partners = () => {
  return (
    <div className='partnersContainer w-full bg-gray-100/50'>
        <div className='max-w-6xl px-2 md:px-4 xl:px-8 mx-auto'>
        <h4 className='text-blue-900 font-normal uppercase text-center mb-8'>our partners & clients</h4>
        <div className='flex flex-wrap justify-center w-full gap-3 mt-[3rem]'>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 rounded-lg bg-white shadow-lg'>
                <img src={Main_Partner} alt="" className='w-32'/>
            </div>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 transition-transform delay-150 rounded-lg bg-white shadow-lg md:bg-transparent md:shadow-none hover:bg-white hover:shadow-lg transition duration-300'>
                <img src={Partner_1}  alt="" className='w-32'/>
            </div>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 transition-transform delay-150 rounded-lg bg-white shadow-lg md:bg-transparent md:shadow-none hover:bg-white hover:shadow-lg transition duration-300'>
                <img src={Partner_2}  alt="" className='w-32'/>
            </div>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 transition-transform delay-150 rounded-lg bg-white shadow-lg md:bg-transparent md:shadow-none hover:bg-white hover:shadow-lg transition duration-300'>
                <img src={Partner_3}  alt="" className='w-32'/>
            </div>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 transition-transform delay-150 rounded-lg bg-white shadow-lg md:bg-transparent md:shadow-none hover:bg-white hover:shadow-lg transition duration-300'>
                <img src={Partner_4}  alt="" className='w-32'/>
            </div>
            <div className='basis-11/12 md:basis-[23.333%] flex items-center justify-center h-[120px] mb-3 transition-transform delay-150 rounded-lg bg-white shadow-lg md:bg-transparent md:shadow-none hover:bg-white hover:shadow-lg transition duration-300'>
                <img src={Partner_5}  alt="" className='w-32'/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Partners
