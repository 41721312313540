import React from 'react'
import './About.css'
import CheckList from '../../Assets/Images/checklist.png'

const AboutSection1 = () => {
    return (
        <div className='aboutSection_1 bg-gray-100'>
            <div className='max-w-6xl mx-auto px-2 md:px-4 xl:px-8'>
                <div className='md:flex'>
                    <div className='basis-1/2 mb-3'>
                        <h2 className='text-5xl text-blue-900 leading-[4rem] lg:w-4/5'>Specialist in Innovative IT Solutions</h2>
                    </div>
                    <div className='basis-1/2 leading-6'>
                        <p>
                            Innovative information technology (IT) solutions can meet any ideas from
                            everyone, everywhere at any point in time. Our firm provides cost-effective and
                            innovative Information technology services that can help you succeed in today's
                            data-driven business environment. We offer IT solutions to individuals and
                            corporate organizations right from when their ideas were conceived to the
                            nurture of their continuous growth and sustainability. We're big believers in
                            doing right by our clients. After all, we are all partners in the development of
                            our world and there's so much more to offer. So we do what we can to meet your
                            needs because we always love to.
                            <br/><br/>
                            Brainbit Solutions is a young, dynamic, and progressively growing company with a
                            very clear objective: to proffer innovative and cost-effective solutions through
                            efficient deployment and integration of information technology. Our team of
                            multi-disciplined young experts offers.
                        </p>
                        <ul className='mt-4'>
                            <li className='flex gap-1 items-center'>
                                <img src={CheckList} alt="" className='w-4 text-blue-900'/>
                                Web application Development</li>
                            <li className='flex gap-1 items-center'>
                                <img src={CheckList} alt="" className='w-4 text-blue-900'/>
                                Enterprise Software Application Solutions</li>
                            <li className='flex gap-1 items-center'>
                                <img src={CheckList} alt="" className='w-4 text-blue-900'/>
                                Professional Supply, Maintenance, and repair services of IT tools</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection1