import React from 'react'
import Navigation from '../Navigation/Navigation'
import Hero from '../Hero/Hero'
import Solution from '../Solutions/Solution'
import OpenSection from '../OpenSection'
import Services from '../Solutions/Services'
import Partners from '../Partners/Partners'
import Testimonials from '../Testimonials/Testimonials'
import Quote from '../Contact/Quote'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <>
    <Navigation />
    <Hero />
    <Solution />
    <OpenSection />
    <Services />
    <Partners />
    <Testimonials />
    <Quote />
    <Footer />
    </>
  )
}

export default Home